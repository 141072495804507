const getJson = (item) => {
    return {
      name : item.name,
      dateStart : item.dateStart,
      dateEnd : item.dateEnd,
    };
  };

  const sendJson = (item) => {
    return {
      name : item.name,
      dateStart : item.dateStart,
      dateEnd : item.dateEnd,
    };
  };

  const baseJson = () => {
    return {
      id : null,
      name : null,
      dateStart : null,
      dateEnd : null,
    };
  };


  export const experienceModel = {
    sendJson , getJson, baseJson,
  };
