export function uploadImage(loader)
{
    return {
        upload :() => {
            return new Promise(async (resolve, reject) => {
                loader.file.then(async (file) => {
                    try{
                   if(!file) return;
                  const data = new FormData();
                  data.append("files" , file); 
                  data.append('code', "NEWS")
                //  formData.append('code', "NEWS")
                  axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,data).then((response) => {
                    console.log("response.data.code ", response.data.code);
                    if (response.data != null && response.data.code == 0)
                        {
                            resolve({default : `${process.env.VUE_APP_API_URL}files/view/`+ response.data.data.fileId})
                        }
                  }).catch((error) => {
                    // Handle error here
                    console.error('Lỗi khi upload file:', error);
                  });
                    }catch (error){
                        reject(error);
                    }   
                })
              
            })
        }
    }
}