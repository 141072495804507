<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Login Tab Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="@/assets/img/phuongthanh/home.jpg"
                      class="img-fluid"
                      alt="Doccure Login"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3 class="text-center">ĐĂNG NHẬP </h3>
                      <p class="text-center" style="font-weight: bold; color: #000;">NHA KHOA PHƯƠNG THÀNH</p>
                    </div>
                    <form @submit.prevent="submitForm">
                      <div class="mb-3 form-focus">
                        <input type="email" class="form-control floating" />
                        <label class="focus-label">Tài khoản</label>
                      </div>
                      <div class="mb-3 form-focus">
                        <input type="password" class="form-control floating" />
                        <label class="focus-label">Mật khẩu</label>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" to="forgot-password"
                          >Forgot Password ?</router-link
                        >
                      </div>
                      <div style="display: flex; justify-content: space-around;">
                        <router-link to="/register">
                          <b-button
                            class="btn btn-warning login-btn"
                            type="submit"

                          >
                            ĐĂNG KÝ
                          </b-button>
                        </router-link>
                        <b-button
                          class="btn btn-primary btn-login"
                          type="submit"
                        >
                          ĐĂNG NHẬP
                        </b-button>
                      </div>
                      <div class="login-or">
                        <span class="or-line"></span>
                        <span class="span-or">or</span>
                      </div>
                      <div class="text-center dont-have">
                        Don’t have an account?
                        <router-link to="/register">ĐĂNG KÍ</router-link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- /Login Tab Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <indexfivefooter></indexfivefooter>
    </div>
  </div>
</template>
<script>
import {notifyModel} from "@/models/notifyModel";

export default {
  methods: {
      async submitForm() {
        await this.$store.dispatch("authStore/login", {"userName": "admin", "password": "DongThap@123"}).then((res) => {
          if (res && res.code ==0 ) {
            this.listMenu = res.data.menu;
            localStorage.setItem('auth-user', JSON.stringify(res.data));
            // window.location.href="/admin"
            // this.$router.push("/quan-tri/profile");
            window.location.href = "/quan-tri/danh-sach";
          }
          this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res))
        })
      },
  },
};
</script>
