<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <!-- Doctor Widget -->
        <div class="card">
          <div class="card-body">
            <div class="doctor-widget">
              <div class="doc-info-left">
                <div class="doctor-img">
                  <img
                      src="../../../assets/img/phuongthanh/avatar01.jpeg"
                      class="img-fluid"
                      alt="User Image"
                  />
                </div>
                <div class="doc-info-cont">
                  <h4 class="doc-name">BS. PHẠM KIM THÀNH</h4>
<!--                  <p class="doc-speciality">Cạo vôi răng - Điều trị viêm nha chu</p>-->
                  <p class="doc-department">
                    <img
                        src="../../../assets/img/specialities/specialities-05.png"
                        class="img-fluid"
                        alt="Speciality"
                    />Nha Sĩ
                  </p>
                  <div class="clinic-details">
                    <ul class="clinic-gallery">
                      <li
                          class="ms-1"
                          v-for="(image, index) in galleryImages"
                          :key="index"
                          @click="() => show(index)"
                      >
                        <a data-fancybox="gallery">
                          <img
                              :src="require(`@/assets/img/phuongthanh/${image.src}`)"
                              alt="Feature"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="clinic-services">
                    <span>Dán sứ veneer</span>
                    <span>Điều trị viêm nha chu</span>
                  </div>
                </div>
              </div>
              <div class="doc-info-right">
                <div class="clini-infos">
                  <ul>
                    <li><i class="far fa-thumbs-up"></i> 99%</li>
                    <li><i class="far fa-comment"></i> 35 Feedback</li>
                    <li><i class="fas fa-map-marker-alt"></i> Newyork, USA</li>
                    <li><i class="far fa-money-bill-alt"></i> $100 per hour</li>
                  </ul>
                </div>
                <div class="doctor-action">
                  <a href="javascript:void(0)" class="btn btn-white fav-btn">
                    <i class="far fa-bookmark"></i>
                  </a>
                  <router-link to="chat" class="btn btn-white msg-btn">
                    <i class="far fa-comment-alt"></i>
                  </router-link>
                  <a
                      href="javascript:void(0)"
                      class="btn btn-white call-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#voice_call"
                  >
                    <i class="fas fa-phone"></i>
                  </a>
                  <a
                      href="javascript:void(0)"
                      class="btn btn-white call-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#video_call"
                  >
                    <i class="fas fa-video"></i>
                  </a>
                </div>
                <div class="clinic-booking">
                  <router-link class="apt-btn" to="dat-lich">Đặt lịch hẹn</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Doctor Widget -->
        <!-- Doctor Details Tab -->
        <div class="card">
          <div class="card-body pt-0">
            <!-- Tab Menu -->
            <nav class="user-tabs mb-4">
              <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                <li class="nav-item">
                  <a class="nav-link active" href="#doc_overview" data-bs-toggle="tab"
                  >Hồ sơ chuyên gia</a
                  >
                </li>
              </ul>
            </nav>
            <!-- /Tab Menu -->
            <!-- Tab Content -->
            <div class="tab-content pt-0">
              <!-- Overview Content -->
              <hoSoChuyenGia></hoSoChuyenGia>
            </div>
          </div>
        </div>
        <!-- /Doctor Details Tab -->
      </div>
    </div>
    <indexfivefooter></indexfivefooter>
    <vue-easy-lightbox
        :visible="visible"
        :index="index"
        :imgs="
        galleryImages.map((image) => ({
          src: require(`@/assets/img/phuongthanh/${image.src}`),
        }))
      "
        @hide="visible = false"
        @on-prev="handlePrev"
        @on-next="handleNext"
    >
    </vue-easy-lightbox>
  </div>
</template>
<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      title: "Hồ sơ bác sĩ",
      text: "Trang chủ",
      text1: "Hồ sơ bác sĩ",
      visible: false,
      index: 0,
      galleryImages: [
        {
          src: "dieutri.jpg",
        },
        {
          src: "dieutri01.jpg",
        },
        {
          src: "dieutri02.jpg",
        },
        {
          src: "dieutri03.jpg",
        },
      ],
    };
  },
  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handlePrev(oldIndex, newIndex) {
      console.log("when prev btn click or user swipe right ----");
      console.log("oldIndex of imgs:", oldIndex);
      console.log("newIndex of imgs:", newIndex);
    },
    handleNext(oldIndex, newIndex) {
      console.log("when next btn click or user swipe left ----");
      console.log("oldIndex of imgs:", oldIndex);
      console.log("newIndex of imgs:", newIndex);
    },
  },
};
</script>
