const getJson = (item) => {
    return {
        id: item.id,
        code: item.code,
        name: item.name,
        collectionName: item.collectionName
    }
}

const sendJson = (item) => {
    return {
        id: item.id,
        code: item.code,
        name: item.name,
        collectionName: item.collectionName
    }
}

const convertJson = (item, collectionName) => {
    return {
        id: item.id,
        code: item.code,
        name: item.name,
        collectionName: collectionName
    }
}

const baseJson = () => {
    return {
        id: null,
        code: null,
        name: null,
        collectionName: null
    }
}

export const commonModel = {
   sendJson , getJson, baseJson,  convertJson
}
