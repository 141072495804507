<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { loadStyles } from "@/loadStyles";

// import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
// import { Link } from '@ckeditor/ckeditor5-link';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

export default {
  setup() {
    loadStyles();


    // Your other setup logic
    // ...

    return {};
  },
  name: "App",
  // data() {
  //   return {
  //     editor: ClassicEditor,
  //     editorData: '<p>Content of the editor.</p>',
  //     editorConfig: {
  //       plugins: [
  //         Essentials,
  //         Bold,
  //         Italic,
  //         Link,
  //         Paragraph
  //       ],
  //
  //       toolbar: {
  //         items: [
  //           'bold',
  //           'italic',
  //           'link',
  //           'undo',
  //           'redo'
  //         ]
  //       }
  //     }
  //   };
  // }
};
</script>
