<template>
  <!-- Profile Widget -->
  <div class="card widget-profile pat-widget-profile">
    <div class="card-body">
      <div class="pro-widget-content">
        <div class="profile-info-widget">
          <a href="javascript:;" class="booking-doc-img">
            <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
          </a>
          <div class="profile-det-info">
            <h3>
              <router-link to="patient-profile">Richard Wilson</router-link>
            </h3>
            <div class="patient-details">
              <h5><b>Patient ID :</b> PT0016</h5>
              <h5 class="mb-0"><i class="fas fa-map-marker-alt"></i> Newyork, USA</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="patient-info">
        <ul>
          <li>Phone <span>+1 952 001 8563</span></li>
          <li>Age <span>38 Years, Male</span></li>
          <li>Blood Group <span>AB+</span></li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /Profile Widget -->
</template>
