<template>
  <div class="main-wrapper">
    <pharmacyheader></pharmacyheader>
    <pharmacysidebar></pharmacysidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <adminbreadcrumb :title="title" :text="text" :text1="text1" />
        <!-- /Page Header -->

        <div class="row">
          <div class="col-md-12">
            <div class="profile-header">
              <div class="profile-name">
                <h4>Thông tin cá nhân</h4>
              </div>
              <div class="row align-items-center">
                <div class="col-md-12 col-lg-12 col-xl-12">
                      <!-- Profile Settings Form -->
                      <form style="color: #000;">
                        <div class="setting-card">
                          <div class="change-avatar">
                            <div class="profile-img">
                              <img
                                src="@/assets/img/phuongthanh/avatar01.jpeg"
                                alt="User Image"
                              />
                            </div>
                            <div class="upload-img" style="margin-left: 20px;">
                              <div class="change-photo-btn">
                                <span class="me-3"><i class="fa fa-upload"></i> Upload Photo</span>
                                <input type="file" class="upload" />
                              </div>
                              <small class="form-text text-muted"
                                >Allowed JPG, GIF or PNG. Max size of 2MB</small
                              >
                            </div>
                          </div>
                        </div>
                        <div class="setting-title">
                          <h5>Thông tin</h5>
                        </div>
                        <div class="setting-card">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="mb-2">Họ và tên</label>
                                <input 
                                  v-model="model.fullName"
                                  type="text" 
                                  class="form-control" 
                                  value="Richard" 
                                />
                              </div>
                            </div>
                            
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">Ngày tháng năm sinh</label>
                                <div class="cal-icon">
                                  <!-- <Field name="createdAtShow" v-slot="{ field }">
                                    <VueDatePicker
                                        v-bind="field"
                                        v-model="model.createdAtShow"
                                        :enable-time-picker="false"
                                        :format="format"
                                        :locale="locale"
                                        auto-apply
                                        ONCLICK=""
                                        :class="{ 'is-invalid': errors.createdAtShow }"
                                    />
                                  </Field> -->
                                  <datepicker
                                    v-model="startdate"
                                    placeholder="Date"
                                    class="form-control datetimepicker"
                                    :editable="true"
                                    :clearable="false"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">Căn cước công dân</label>
                                <input type="text" class="form-control" value="000000000000" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">Email</label>
                                <input
                                  v-model="model.email"
                                  type="email"
                                  class="form-control"
                                  value="PHƯƠNG THÀNH"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">Số điện thoại</label>
                                <input type="text" value="+1 202-555-0125" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="setting-title">
                          <h5>Địa chỉ</h5>
                        </div>

                        <div class="setting-card">
                          <div class="row">
                            <div class="col-12">
                              <div class="mb-3">
                                <label class="mb-2">Address</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  value="806 Twin Willow Lane"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">City</label>
                                <input type="text" class="form-control" value="Old Forge" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">State</label>
                                <input type="text" class="form-control" value="Newyork" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">Zip Code</label>
                                <input type="text" class="form-control" value="13420" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="mb-3">
                                <label class="mb-2">Country</label>
                                <input type="text" class="form-control" value="United States" />
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="submit-section" style="display: flex; justify-content: flex-end;">
                          <b-button
                              type="submit"
                              style="background-color: #e9ab2e; border: none;"
                          >
                            Lưu thông tin
                          </b-button>
                        </div>
                      </form>
                      <!-- /Profile Settings Form -->
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- Edit Details Modal -->
  <div class="modal fade" id="edit_personal_details" aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Personal Details</h5>
          <b-button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></b-button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row form-row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">First Name</label>
                  <input type="text" class="form-control" value="John" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Last Name</label>
                  <input type="text" class="form-control" value="Doe" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Date of Birth</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Email ID</label>
                  <input type="email" class="form-control" value="johndoe@example.com" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Mobile</label>
                  <input type="text" value="+1 202-555-0125" class="form-control" />
                </div>
              </div>
              <div class="col-12">
                <h5 class="form-title"><span>Address</span></h5>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-2">Address</label>
                  <input type="text" class="form-control" value="4663 Agriculture Lane" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">City</label>
                  <input type="text" class="form-control" value="Miami" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">State</label>
                  <input type="text" class="form-control" value="Florida" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Zip Code</label>
                  <input type="text" class="form-control" value="22434" />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label class="mb-2">Country</label>
                  <input type="text" class="form-control" value="United States" />
                </div>
              </div>
            </div>
            <b-button type="submit" class="btn btn-primary w-100">
              Save Changes
            </b-button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Details Modal -->
</template>

<script>
import { ref } from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import { userModel } from "@/models/userModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';
import {notifyModel} from "@/models/notifyModel";

const currentDate = ref(new Date());

export default {
  components: {
    VueDatePicker,
    Treeselect,
    VueMultiselect,
    Form,
    Field,
  },
  data() {
    return {
      title: "Profile",
      text: "Dashboard",
      text1: "Profile",
      startdate: currentDate,
      model: userModel.baseJson(),
      theModal: null,
      format : `dd/MM/yyyy`,
      locale: 'vi',
    };
  },
  created() {
    this.handleProfile();
    this.getInfomation(this.handleProfile().id);
  },

  watch:{
    reloadAuthUser(value) {
      this.loadData();
    },
  },

  methods:{
    async getInfomation(id){
      await  this.$store.dispatch("userStore/getById",{"id" : id}).then((res) =>{
        this.model = userModel.getJson(res.data)
      })
    },

    async loadData() {
      await this.getInfomation();
    },

    handleProfile() {
      const profile = localStorage.getItem("auth-user");
      return JSON.parse(profile);
    },
  },

};
</script>

<style>

.profile-name{
  color: #be8b43;
  margin-bottom: 20px;
}
</style>
