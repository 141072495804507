<template>
  <section class="section news-section">
    <div class="container">
      <div class="pharmacy-section-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="pharmacy-title">
              <h4>Tin tức mới nhất</h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pharmacy-title-link">
              <router-link to="blog-details"
                >Xem tất cả <i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="news-grid flex-fill">
            <div class="news-image">
              <router-link to="blog-details">
                <img
                  src="@/assets/img/blog/blog-21.jpg"
                  class="img-fluid"
                  alt="Post Image"
                />
              </router-link>
              <div class="news-date">
                <h5>18</h5>
                <p>T8</p>
              </div>
            </div>
            <div class="news-content">
              <ul class="nav">
                <li>
                  <p>
                    <img src="@/assets/img/icons/tag-icon.svg" alt="Icon" />
                    Food
                  </p>
                </li>
                <li>
                  <p>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/admin-icon.svg"
                      alt="Icon"
                    />
                    By Admin
                  </p>
                </li>
                <li>
                  <p>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/comment-icon.svg"
                      alt="Icon"
                    />
                    65 Comments
                  </p>
                </li>
              </ul>
              <h3 class="news-title">
                <router-link to="blog-details">
                  Cách mạng hóa việc chăm sóc bệnh nhân: Tương lai của các hiệu thuốc trực tuyến
                </router-link>
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="news-grid flex-fill">
            <div class="news-image">
              <router-link to="blog-details">
                <img
                  src="@/assets/img/blog/blog-22.jpg"
                  class="img-fluid"
                  alt="Post Image"
                />
              </router-link>
              <div class="news-date">
                <h5>19</h5>
                <p>T8</p>
              </div>
            </div>
            <div class="news-content">
              <ul class="nav">
                <li>
                  <p>
                    <img src="@/assets/img/icons/tag-icon.svg" alt="Icon" />
                    Food
                  </p>
                </li>
                <li>
                  <p>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/admin-icon.svg"
                      alt="Icon"
                    />
                    By Admin
                  </p>
                </li>
                <li>
                  <p>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/comment-icon.svg"
                      alt="Icon"
                    />
                    48 Comments
                  </p>
                </li>
              </ul>
              <h3 class="news-title">
                <router-link to="blog-details">
                  Điều hướng thế giới thực phẩm bổ sung: Quan điểm của một dược sĩ
                </router-link>
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="news-grid flex-fill">
            <div class="news-image">
              <router-link to="blog-details">
                <img
                  src="@/assets/img/blog/blog-23.jpg"
                  class="img-fluid"
                  alt="Post Image"
                />
              </router-link>
              <div class="news-date">
                <h5>20</h5>
                <p>T8</p>
              </div>
            </div>
            <div class="news-content">
              <ul class="nav">
                <li>
                  <p>
                    <img src="@/assets/img/icons/tag-icon.svg" alt="Icon" />
                    Food
                  </p>
                </li>
                <li>
                  <p>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/admin-icon.svg"
                      alt="Icon"
                    />
                    By Admin
                  </p>
                </li>
                <li>
                  <p>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/comment-icon.svg"
                      alt="Icon"
                    />
                    26 Comments
                  </p>
                </li>
              </ul>
              <h3 class="news-title">
                <router-link to="blog-details">
                  Phía sau quầy tính tiền: Cái nhìn thoáng qua về cuộc sống hàng ngày của một dược sĩ
                </router-link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
