<template>
    <div class="main-Wrapper">
      <!-- Page Wrapper -->
        <Form :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit">
          <div class="row">
            <div class="col-md-12" style="padding: 10px 20px;">
              <div class="card-tools" style="float: right;">
                <b-button
                    style="background-color: #e9ab2e; border: none;"
                    class="btn-submit w-md"
                    @click="handleSubmit"
                >
                  Cập nhật
                </b-button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="">Mô tả hồ sơ Bác Sĩ </label><span class="text-danger">*</span>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-3">
                <Field
                    as="textarea"
                    v-model="model.decribe"
                    placeholder="Vui lòng nhập mô tả"
                    name="content"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.content }"
                />
                <div class="invalid-feedback">{{ errors.content }}</div>
              </div>
            </div>
          </div>
        </Form>
    </div>
    <pharmacymodel />
    <pharmacydelete />
  </template>
  <script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { educationModel } from "@/models/educationModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import doctor from "@/views/admin/pages/doctor/index";
import {doctorModel} from "@/models/doctorModel";
  export default {
    components: {
      VueDatePicker,
      Treeselect,
      VueMultiselect,
      Form,
    Field,
    },
    data() {
      return {
        title: "DANH SÁCH",

      };
    },
    name: "pharmacy/user",
    props: {
      model: {
        type: Object,
        default: () => ({}),
      },
    },
    created() {


    },

    methods: {
        async handleSubmit() {
            this.model.id = this.$route.params.id;
            await this.$store.dispatch("doctorStore/updateDecribe", this.model ).then((res) => {
                if (res != null && res.code ===0) {
                   this.model = res.data;
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },
    }
};
</script>

<style>
textarea.form-control {
  width: 100%;
  height: 360px;
}
</style>

