<template>
    <!-- Friday Slot -->
    <div id="slot_friday" class="tab-pane fade">
        <h4 class="card-title d-flex justify-content-between">
            <span>Time Slots</span>
            <a class="edit-link" data-bs-toggle="modal" href="#add_time_slot"><i class="fa fa-plus-circle"></i> Add Slot</a>
        </h4>
        <p class="text-muted mb-0">Not Available</p>
    </div>
    <!-- /Friday Slot -->
</template>