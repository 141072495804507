<template>
  <!-- Service Section -->
  <!-- <section class="service-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider mb-2">
            <h2 class="section-title"><span>Dịch vụ</span> của chúng tôi</h2>
          </div>
        </div>
      </div>
      <div class="row row-gap aos" data-aos="fade-up">
        <div
          class="col-md-6 col-sm-6 col-lg-4"
          v-for="item in list"
          :key="item.id"
        >
          <div class="listing-card">
            <div class="listing-img">
              <router-link :to="`ban-tin/${item.id}`"
                ><img
                  :src="url + item.fileImage.fileId"
                  class="img-fluid img-service"
                  alt="Image"
              /></router-link>
            </div>
            <div class="listing-content">
              <div class="listing-details">
                <div class="listing-title">
                  <h3>
                    <router-link to="ban-tin/66418c2a6f6c131f69c99fb9">{{ item.name }}</router-link>
                  </h3>
                </div>
                <div class="listing-profile-details">
                  <div class="listing-user">
                    <div class="listing-profile-img">
                      <router-link to="/ho-so-bac-si"
                        ><img
                          src="@/assets/img/phuongthanh/avatar01.jpeg"
                          class="img-fluid"
                          alt="Image"
                      /></router-link>
                    </div>
                    <div class="listing-user-details">
                      <span>Chuyên gia</span>
                      <h6>
                        <router-link to="ban-tin/66418c2a6f6c131f69c99fb9">Phương Thành</router-link>
                      </h6>
                    </div>
                  </div>
                  <div class="listing-btn">
                    <router-link to="ban-tin/66418c2a6f6c131f69c99fb9" class="btn consult-btn"
                      >Chi tiết</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- /Service Section -->
  <!-- Need To Know -->
  <section class="need-to-know-section">
    <div class="container">
      <div class="row mb-3">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider text-center mb-2">
            <h2 class="section-title">Kiến thức <span>nha khoa</span></h2>
          </div>
          <div class="section-header-one section-header-slider text-center">
            <div class="section-title title-desc">
              Nha khoa Phương Thành có đầy đủ các chuyên khoa,
              với mỗi khoa là đội ngũ bác sĩ chuyên sâu trong từng lĩnh vực để đem đến kết quả tối ưu cho khách hàng.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 aos" data-aos="fade-up"
             v-for="(value) in this.listDentalKnowledge" :key="value.id"
        >
          <div class="service-item">
            <router-link :to="{
                        path: `/ban-tin/${value.id}`,
                    }">
              <div>
                <img
                    v-if="value.fileImage"
                    class="img-fluid img-service"
                    :src="value.urlImg"
                    alt="Post Image"
                />
              </div>
              <div class="service-content">
                <div class="service-title mt-3">
                  <p
                    style="
                      color: #000;
                      margin-bottom: 8px;
                      font-family: 'Be Bold';
                      text-transform: uppercase;
                      display: -webkit-box !important;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      min-height: 55px;"
                    >
                    {{value.name}}
                  </p>
                </div>
                <div class="service-desc mb-3">
                  <p>
                    {{value.describe}}
                  </p>
                </div>
                <div class="service-btn">
                  <b-button
                      class="btn-chitiet"
                  >
                    Xem thêm
                  </b-button>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Need To Know -->

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import indexFiveService from "@/assets/json/indexfiveservice.json";
export default {
  data() {
    return {
      indexFiveService: indexFiveService,
      list: [],
      listDentalKnowledge: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
    this.getData();
    this.getDataDentalKnowledge();
  },
  methods: {
    async getData() {
        let params = {
            start: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
        }
        await this.$store.dispatch("dichVuStore/getPagingParams", params ).then(res => {
            this.list = res.data.data
        });
    },
    async getDataDentalKnowledge() {
      let params = {
        start: 1,
        limit: 6,
        sortBy: this.sortBy,
      }
      await this.$store.dispatch("newsStore/getPagingParamsDentalKnowledge", params ).then(res => {
        this.listDentalKnowledge = res.data;
      });
    },
    toggleFavorite(item) {
      item.favorite = !item.favorite;
    },
  },
};
</script>

<style>
.img-service{
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px;
}

@media (max-width: 576px) {
  .img-service{
    height: 300px;
  }
}
</style>
