const  getJson = (item) => {
  return {
    id: item.id,
    name: item.name,
    icon: item.icon,
    router: item.router,
    isPublic: item.isPublic,
    menu : item.menu,
    sort: item.sort,
  };
};

const sendJson  = (item) => {
  return {
    id: item.id,
    name: item.name,
    icon: item.icon,
    router: item.router,
    isPublic: item.isPublic,
    menu : item.menu,
    sort: item.sort,
  };
};

const baseJson = () => {
  return {
    id: null,
    name: null,
    icon: null,
    menu : null,
    router: null,
    isPublic: true,
    sort: 0,
  };
};

export const menuAppModel = {
  sendJson , getJson, baseJson,
};
