<template>
  <section class="section products-sec recent-product-sec">
    <div class="container">
      <div class="pharmacy-section-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="pharmacy-title">
              <h4>Sản phẩm gần đây</h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pharmacy-title-link">
              <router-link to="product-all"
                >Xem tất cả <i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-27.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Sức khỏe</h6>
              <h4><router-link to="product-all">Viên thuốc cảm lạnh</router-link></h4>
              <span class="badge">60 viên</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$150<span>$199</span></h5>
                </div>
                <router-link to="product-all" class="cart-icon">
                  <i class="feather-shopping-bag"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-28.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Cân nậng</h6>
              <h4><router-link to="product-all">Thanh Protein</router-link></h4>
              <span class="badge">65 g</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$120<span>$150</span></h5>
                </div>
                <div class="cart-count">
                  <div class="custom-increment cart">
                    <div class="input-group1">
                      <span class="input-group-btn">
                        <button
                          type="button"
                          class="quantity-left-minus3 btn btn-danger btn-number"
                          data-type="minus"
                          data-field=""
                        >
                          <span><i class="fas fa-minus"></i></span>
                        </button>
                      </span>
                      <input
                        type="text"
                        id="quantity3"
                        name="quantity3"
                        class="input-number"
                        value="10"
                      />
                      <span class="input-group-btn">
                        <button
                          type="button"
                          class="quantity-right-plus3 btn btn-success btn-number"
                          data-type="plus"
                          data-field=""
                        >
                          <span><i class="fas fa-plus"></i></span>
                        </button>
                      </span>
                    </div>
                  </div>
                  <router-link to="product-all" class="cart-icon check-icon">
                    <i class="feather-check"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-29.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Sức khỏe</h6>
              <h4><router-link to="product-all">Acetoheal</router-link></h4>
              <span class="badge">40 viên</span>
              <span class="sticker">5% OFF</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$250<span>$350</span></h5>
                </div>
                <router-link to="product-all" class="cart-icon">
                  <i class="feather-shopping-bag"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="products-card">
            <div class="product-card-img">
              <router-link to="product-all"
                ><img src="@/assets/img/products/product-30.png" alt=""
              /></router-link>
            </div>
            <div class="product-content">
              <h6>Cân nậng</h6>
              <h4><router-link to="product-all">Apexitecan</router-link></h4>
              <span class="badge">20 viên</span>
              <div class="product-cart">
                <div class="cart-price">
                  <h5>$279<span>$300</span></h5>
                </div>
                <router-link to="product-all" class="cart-icon">
                  <i class="feather-shopping-bag"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 d-flex">
          <div class="products-card w-100">
            <div class="product-icon">
              <span><img src="@/assets/img/icons/product-icon-01.svg" alt="" /></span>
            </div>
            <div class="product-content">
              <h4>Miễn phí vận chuyển</h4>
              <p>Miễn phí vận chuyển với chiết khấu</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 d-flex">
          <div class="products-card w-100">
            <div class="product-icon">
              <span><img src="@/assets/img/icons/product-icon-02.svg" alt="" /></span>
            </div>
            <div class="product-content">
              <h4>Hỗ trợ 24/7</h4>
              <p>Truy cập ngay vào liên hệ</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 d-flex">
          <div class="products-card w-100">
            <div class="product-icon">
              <span><img src="@/assets/img/icons/product-icon-03.svg" alt="" /></span>
            </div>
            <div class="product-content">
              <h4>Thanh toán thành công 100%</h4>
              <p>Chúng tôi đảm bảo sẽ tiết kiệm tiền của bạn</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 d-flex">
          <div class="products-card w-100">
            <div class="product-icon">
              <span><img src="@/assets/img/icons/product-icon-04.svg" alt="" /></span>
            </div>
            <div class="product-content">
              <h4>Đảm bảo hoàn tiền</h4>
              <p>Đảm bảo hoàn tiền trong 30 ngày</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
