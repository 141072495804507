const getJson = (item) => {
    return {
      id: item.id,
      header: item.header,
      clinicSpace : item.clinicSpace ,
      customerReviews : item.customerReviews,
      footer : item.footer ,
      promotions : item.promotions,
    };
  };

  const sendJson = (item) => {
    return {
      id: item.id,
      header: item.header,
      clinicSpace : item.clinicSpace ,
      customerReviews : item.customerReviews,
      footer : item.footer ,
      promotions : item.promotions,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      header: null,
      clinicSpace : null,
      customerReviews : null,
      promotions : null,
      footer : null,
    };
  };


  export const pageInfoModel = {
    sendJson , getJson, baseJson,
  };
