const getJson = (item) => {
    return {
      level : item.level ,
      trainingPlaces : item.trainingPlaces,
      dateStart : item.dateStart,
      dateEnd : item.dateEnd,
    };
  };

  const sendJson = (item) => {
    return {
      level : item.level ,
      trainingPlaces : item.trainingPlaces,
      dateStart : item.dateStart,
      dateEnd : item.dateEnd,
    };
  };

  const baseJson = () => {
    return {
      id : null,
      level : null ,
      trainingPlaces : null,
      dateStart : null,
      dateEnd : null,
    };
  };


  export const educationModel = {
    sendJson , getJson, baseJson,
  };
