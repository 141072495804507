import {apiClient} from "@/state/modules/apiClient";
const controller = "ListExperience";

export const state = {
    reloadAuthUser: false,
}

export const mutations = {
    SET_RELOADAUTHUSER(state, newValue) {
        state.reloadAuthUser = newValue
    }
}
export const actions = {
    async create({commit}, values) {
        return apiClient.post(controller +"/create", values);
    },
    async delete({commit}, values) {
        return apiClient.post(controller +"/delete", values);
    },
    async update({commit}, values) {
        return apiClient.post(controller +"/update", values);
    },
};
