<template>
  <div class="col-md-7 col-lg-9 col-xl-9">
    <div class="row">
      <div
          class="col-md-12 col-lg-4 col-xl-4 product-custom"
          v-for="item in list"
          :key="item.id"
      >
        <div class="profile-widget profile-lich">
          <div class="doc-img">
            <router-link to="product-description" tabindex="-1">
              <div v-if="item.fileImage != null">
              <img
                  class="img-fluid"
                  alt="Product image"
                  :src="url + item.fileImage.fileId"
              />
              </div>
            </router-link>
            <a href="javascript:void(0)" class="fav-btn" tabindex="-1">
              <i class="far fa-bookmark"></i>
            </a>
          </div>
          <div class="pro-content">
            <h3 class="title pb-1" style="margin-bottom: 0;">
              <router-link to="product-description" tabindex="-1">{{
                  item.name
                }}</router-link>
            </h3>
            <div class="row align-items-center">
              <div class="col-lg-12 pb-1">
                <span class="price">{{ item.describe }}</span>
              </div>

            </div>
            <div class="row row-sm">
              <div class="col-6">
                <router-link to="/ho-so-bac-si" class="btn view-btn"
                >Chi tiết</router-link
                >
              </div>
              <div class="col-6">
                <router-link to="/dat-lich" class="btn book-btn"
                >Đặt lịch</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center">
      <a href="javascript:;" class="btn book-btn1 mb-4">Xem thêm</a>
    </div>
  </div>
</template>
<script>
import productall from "@/assets/json/pharmacy/productall.json";
export default {
  data() {
    return {
      productall: productall,
      Select: ["Rating", "Popular", "Latest", "Free"],
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData(){
      await  this.$store.dispatch("serviceStore/getAll").then((res) =>{
        this.list = res.data || [];
      })
    },

  }
};
</script>
