<template>
    <Form :validation-schema="schema" v-slot="{ errors }" @submit="handleSubmit">
        <div class="row">
            <div class="col-md-12" style="padding: 10px 20px;">
                <div class="card-tools" v-if="isView" style="float: right;">
                    <b-button
                    style="background-color: #e9ab2e; border: none;"
                    :disabled="
                            isWait
                        "
                    @click="changeView"
                    class="btn-submit w-md"
                    >
                        Cập nhật
                    </b-button>
                </div>
                <div v-else class="card-tools" style="float: right;">
                    <b-button
                        style="background-color: #e9ab2e; border: none; margin-right: 10px;"
                        class="mr-2 w-md"
                        :disabled="
                            isWait
                        "
                        @click="onSubmit"
                    >
                        Lưu
                    </b-button>
                    <b-button
                        href="#delete_user"
                        variant="light"
                        data-bs-toggle="modal"
                        class="btn-submit w-md btn-out"
                        :disabled="
                            isWait
                        "
                    >
                        Hủy
                    </b-button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                <label class="text-left">Họ và tên</label>
                <span style="color: red">&nbsp;*</span>
                <Field
                    v-model="model.name"
                    placeholder="Vui lòng nhập tên bác sĩ"
                    name="name"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.name }"
                />
                    <div class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="text-left mb-0">Ngày sinh</label>
                    <span style="color: red" >&nbsp;*</span>
                    <Field name="birthday" v-slot="{ field }">
                    <VueDatePicker
                        v-bind="field"
                        v-model="model.birthday"
                        :enable-time-picker="false"
                        :format="format"
                        :locale="locale"
                        validate="true"
                        auto-apply
                        ONCLICK=""
                        :disabled="isView"
                        :class="{ 'is-invalid': errors.birthday }"
                    />
                    </Field>
                    <div class="invalid-feedback">{{ errors.birthday }}</div>
                </div>
            </div>
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Giới tính</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.gender"
                        :options="listGender"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Tình trạng hôn nhân</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.married"
                        :options="listHonNhan"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                <label class="text-left">Số điện thoại</label>
                <span style="color: red">&nbsp;*</span>
                <input type="hidden" v-model="model.id"/>
                <Field
                    v-model="model.phone"
                    placeholder="Vui lòng nhập số điện thoại"
                    name="phone"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.phone }"
                />
                    <div class="invalid-feedback">{{ errors.phone }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="text-left">Email</label>
                <span style="color: red">&nbsp;*</span>
                <input type="hidden" v-model="model.id"/>
                <Field
                    v-model="model.email"
                    placeholder="Vui lòng nhập email"
                    name="email"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.email }"
                />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="text-left">Căn cước công dân</label>
                <span style="color: red">&nbsp;*</span>
                <input type="hidden" v-model="model.id"/>
                <Field
                    v-model="model.cccd"
                    placeholder="Vui lòng nhập căn cước công dân"
                    name="cccd"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.cccd }"
                />
                    <div class="invalid-feedback">{{ errors.cccd }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="text-left mb-0">Ngày cấp CCCD</label>
                    <span style="color: red" >&nbsp;*</span>
                    <Field name="dateCCCD" v-slot="{ field }">
                    <VueDatePicker
                        v-bind="field"
                        v-model="model.dateCCCD"
                        :enable-time-picker="false"
                        :format="format"
                        :locale="locale"
                        validate="true"
                        auto-apply
                        ONCLICK=""
                        :disabled="isView"
                        :class="{ 'is-invalid': errors.dateCCCD }"
                    />
                    </Field>
                    <div class="invalid-feedback">{{ errors.dateCCCD }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="text-left mb-0">Ngày tham gia BHYT</label>
                    <span style="color: red" >&nbsp;*</span>
                    <Field name="fromDateBHYT" v-slot="{ field }">
                    <VueDatePicker
                        v-bind="field"
                        v-model="model.fromDateBHYT"
                        :enable-time-picker="false"
                        :format="format"
                        :locale="locale"
                        validate="true"
                        auto-apply
                        ONCLICK=""
                        :disabled="isView"
                        :class="{ 'is-invalid': errors.fromDateBHYT }"
                    />
                    </Field>
                    <div class="invalid-feedback">{{ errors.fromDateBHYT }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="text-left mb-0">Đến ngày</label>
                    <span style="color: red" >&nbsp;*</span>
                    <Field name="toDateBHYT" v-slot="{ field }">
                    <VueDatePicker
                        v-bind="field"
                        v-model="model.toDateBHYT"
                        :enable-time-picker="false"
                        :format="format"
                        :locale="locale"
                        validate="true"
                        auto-apply
                        ONCLICK=""
                        :disabled="isView"
                        :class="{ 'is-invalid': errors.toDateBHYT }"
                    />
                    </Field>
                    <div class="invalid-feedback">{{ errors.toDateBHYT }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="text-left">Số CCHN</label>
                <span style="color: red">&nbsp;*</span>
                <input type="hidden" v-model="model.id"/>
                <Field
                    v-model="model.noCCHN"
                    placeholder="Vui lòng nhập số chứng chỉ hành nghề"
                    name="noCCHN"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.noCCHN }"
                />
                    <div class="invalid-feedback">{{ errors.noCCHN }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="text-left mb-0">Thời hạn CCHN</label>
                    <span style="color: red" >&nbsp;*</span>
                    <Field name="dateCCHN" v-slot="{ field }">
                    <VueDatePicker
                        v-bind="field"
                        v-model="model.dateCCHN"
                        :enable-time-picker="false"
                        :format="format"
                        :locale="locale"
                        validate="true"
                        auto-apply
                        ONCLICK=""
                        :disabled="isView"
                        :class="{ 'is-invalid': errors.dateCCHN }"
                    />
                    </Field>
                    <div class="invalid-feedback">{{ errors.dateCCHN }}</div>
                </div>
            </div>
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Khoa</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.department"
                        :options="listKhoa"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
    
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Phòng</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.room"
                        :options="listPhong"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
    
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Đơn vị</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.unitGroup"
                        :options="listDonVi"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Dịch vụ</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.service"
                        :options="listDichVu"
                        :multiple="true"
                        :disabled="isView"
                        track-by="id"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
            <div class="col-md-6 multi-role">
                <div class="mb-3">
                    <label class="text-left">Chuyên ngành</label>
                    <span style="color: red">&nbsp;*</span>
                    <VueMultiselect
                        v-model="model.majors"
                        :options="listChuyenNganh"
                        :multiple="true"
                        :disabled="isView"
                        track-by="id"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                    </VueMultiselect>
                </div>
            </div>
    
        </div>
        <div class="row mt-2">
            <span style="font-size: 20px;">Nơi sinh</span>
            <div class="col-md-12" v-if="isView">
                <div class="mb-3">
                <label class="text-left">Địa chỉ</label>
                <span style="color: red">&nbsp;*</span>
                <Field
                    v-model="itemsNoiSinh.fullAddress"
                    name="address"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                />
                </div>
            </div>
            <div class="col-md-12" v-if="!isView">
                <div class="mb-3">
                <label class="text-left">Địa chỉ</label>
                <span style="color: red">&nbsp;*</span>
                <Field
                    v-model="itemsNoiSinh.address"
                    placeholder="Vui lòng nhập địa chỉ"
                    name="address"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.address }"
                />
                    <div class="invalid-feedback">{{ errors.address }}</div>
                </div>
            </div>
            <div class="col-md-4" v-if="!isView">
                <div class="mb-3">
                <label class="text-left">Tỉnh</label>
                <span style="color: red">&nbsp;*</span>
                <VueMultiselect
                        v-model="itemsNoiSinh.province"
                        :options="listTinh"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                </VueMultiselect>
                </div>
            </div>
            <div class="col-md-4" v-if="!isView">
                <div class="mb-3">
                <label class="text-left">Thành phố</label>
                <span style="color: red">&nbsp;*</span>
                <VueMultiselect
                        v-model="itemsNoiSinh.district"
                        :options="listTP"
                        :multiple="false"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                        :disabled="!itemsNoiSinh.province || isView"
                    >
                </VueMultiselect>
                </div>
            </div>
            <div class="col-md-4" v-if="!isView">
                <div class="mb-3">
                <label class="text-left">Phường, xã</label>
                <span style="color: red">&nbsp;*</span>
                <VueMultiselect
                        v-model="itemsNoiSinh.commune"
                        :options="listXa"
                        :multiple="false"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                        :disabled="!itemsNoiSinh.district || isView"
                    >
                </VueMultiselect>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <span style="font-size: 20px;">Địa chỉ thường trú</span>
            <div class="col-md-12">
                <div class="mb-3">
                <label class="text-left">Địa chỉ</label>
                <span style="color: red">&nbsp;*</span>
                <Field
                    v-model="itemsDiaChi.address"
                    placeholder="Vui lòng nhập địa chỉ"
                    name="address"
                    type="text"
                    class="form-control"
                    :disabled="isView"
                    :class="{ 'is-invalid': errors.address }"
                />
                    <div class="invalid-feedback">{{ errors.address }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3">
                <label class="text-left">Tỉnh</label>
                <span style="color: red">&nbsp;*</span>
                <VueMultiselect
                        v-model="itemsDiaChi.province"
                        :options="listTinhDiaChi"
                        :multiple="false"
                        :disabled="isView"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                    >
                </VueMultiselect>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3">
                <label class="text-left">Thành phố</label>
                <span style="color: red">&nbsp;*</span>
                <VueMultiselect
                        v-model="itemsDiaChi.district"
                        :options="listTPDiaChi"
                        :multiple="false"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                        :disabled="!itemsDiaChi.province || isView"
                    >
                </VueMultiselect>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3">
                <label class="text-left">Phường, xã</label>
                <span style="color: red">&nbsp;*</span>
                <VueMultiselect
                        v-model="itemsDiaChi.commune"
                        :options="listXaDiaChi"
                        :multiple="false"
                        label="name"
                        placeholder="Nhấp vào để chọn"
                        selectLabel="Nhấn vào để chọn"
                        deselectLabel="Nhấn vào để xóa"
                        :disabled="!itemsDiaChi.district || isView"
                    >
                </VueMultiselect>
                </div>
            </div>
        </div>
    </Form>
</template>

<script >

import VueDatePicker from '@vuepic/vue-datepicker';
import { doctorModel } from "@/models/doctorModel";
import Treeselect from 'vue3-treeselect';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import {notifyModel} from "@/models/notifyModel";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
  export default {
    components: {
        VueDatePicker,
        Treeselect,
        VueMultiselect,
        Form,
        Field,
    },
    data() {
      return {
        title: "DANH SÁCH",

        model: { ...this.model },
        activeTab: 0, // Tab mặc định là tab đầu tiên
        list: [],
        currentPage: 1,
        numberOfElement: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'age',
        sortDesc: false,
        url : `${process.env.VUE_APP_API_URL}files/view/`,
        urlFile:`${process.env.VUE_APP_API_URL}files/view`,
        format : `dd/MM/yyyy`,
        locale: 'vi',
        isView: true,
        originalData: null,
        itemsNoiSinh:{
            address: null,
            province: null,
            district: null,
            commune: null,
            fullAddress: null,
        },
        itemsDiaChi:{
            address: null,
            province: null,
            district: null,
            commune: null,
            fullAddress: null,
        },
        listTinh: [],
        listTP: [],
        listXa: [],
        listTinhDiaChi: [],
        listTPDiaChi: [],
        listXaDiaChi: [],
      };
    },
    name: "pharmacy/user",

    props: {
        model: {
            type: Object,
            default: () => ({}),
        },
        listGender: {
            type: Array,
            required: true
        },
        listKhoa: {
            type: Array,
            required: true
        },
        listPhong: {
            type: Array,
            required: true
        },
        listDonVi: {
            type: Array,
            required: true
        },
        listDichVu: {
            type: Array,
            required: true
        },
        listHonNhan: {
            type: Array,
            required: true
        },
        listChuyenNganh: {
            type: Array,
            required: true
        },
        listLevel: {
            type: Array,
            required: true
        },
        itemsNoiSinh: {
            type: Object,
            required: true
        },
        itemsDiaChi: {
            type: Object,
            required: true
        },
        listTinh: {
            type: Array,
            required: true
        },
        listTP: {
            type: Array,
            required: true
        },
        listXa: {
            type: Array,
            required: true
        },
        listTinhDiaChi: {
            type: Array,
            required: true
        },
        listTPDiaChi: {
            type: Array,
            required: true
        },
        listXaDiaChi: {
            type: Array,
            required: true
        }
    },

    created() {
      this.getListTinh();
      this.getListTP("All");
      this.getListXa("All");
    },

    

    watch: {
        'itemsNoiSinh.province': {
            handler(val) {
                if (val) {
                    this.getListTP(val.code);
                } else {
                    this.listTP = [];
                }
            },
            deep: true
        },
        'itemsNoiSinh.district': {
            handler(val) {
                if (val) {
                    this.getListXa(val.code);
                } else {
                    this.listXa = [];
                }
            },
            deep: true
        },
        'itemsDiaChi.province': {
            handler(val) {
                if (val) {
                    this.getListTP(val.code);
                } else {
                    this.listTPDiaChi = [];
                }
            },
            deep: true
        },
        'itemsDiaChi.district': {
            handler(val) {
                if (val) {
                    this.getListXa(val.code);
                } else {
                    this.listXaDiaChi = [];
                }
            },
            deep: true
        }
    },

    mounted() {
        // Gắn sự kiện Bootstrap modal
        // const modalElement = this.$refs.deleteUserModal;
        // modalElement.addEventListener('show.bs.modal', this.saveData);
    },
    methods: {
        async getListTinh(){
            await  this.$store.dispatch("geographicalStore/getList", "0").then((res) =>{
                this.listTinh = res.data || [];
                this.listTinhDiaChi = res.data || [];
            })
        },
        async getListTP(code){
            await  this.$store.dispatch("geographicalStore/getListChild", code).then((res) =>{
                this.listTP = res.data || [];
                this.listTPDiaChi = res.data || [];
            })
        },
        async getListXa(code){
            await  this.$store.dispatch("geographicalStore/getListChild", code).then((res) =>{
                this.listXa = res.data || [];
                this.listXaDiaChi = res.data || [];
            })
        },

        changeView() {
            this.isView = false;
            this.$route.params.isView = false;
        },

        saveData() {
            // Lưu trữ bản sao của dữ liệu gốc khi mở modal
            this.originalData = { ...this.model };
            console.log("DATA: ", this.originalData);
        },

        huySubmit() {
            // Khôi phục dữ liệu gốc và đóng modal
            this.model = { ...this.originalData };
            this.isView = true
        },

        async handleUpdate(id) {
            await this.$store.dispatch("doctorStore/getById", {id : id}).then((res) => {
                if (res != null && res.code ===0) {
                    console.log(res)
                    this.model = doctorModel.getJson(res.data);
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },
        handleShowDeleteModal(id) {
            this.model.id = id;
            this.showDeleteModal = true;
        },
        async handleDelete() {
            if (this.model.id != 0 && this.model.id != null && this.model.id) {
                await this.$store.dispatch("doctorStore/delete", { 'id': this.model.id }).then((res) => {
                if (res != null && res.code ===0) {
                    this.showDeleteModal = false;
                    this.$emit('submit');
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
                });
            }
        },
        async onSubmit() {
            this.model.placeOfBirth = this.itemsNoiSinh;
            this.model.address = this.itemsDiaChi;
            await this.$store.dispatch("doctorStore/updateInfo", this.model).then((res) => {
                if (res != null && res.code ===0) {

                    this.$emit('submit');
                    this.model= doctorModel.baseJson();
                    this.isView = true;
                }
                this.$store.dispatch("snackBarStore/addNotify", notifyModel.addMessage(res));
            });
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
            },
        async upload() {

            if (this.model != null && this.model.fileImage != null)
            {
            // console.log("LOG UPDATE NE : ", event.target.files )
            axios.post(`${process.env.VUE_APP_API_URL}Files/delete/${this.model.fileImage.fileId}`).then((response) => {
                this.model.files = null;
                //   console.log('log model file remove', this.model.files);
            }).catch((error) => {
                // Handle error here
                //    console.error('Error deleting file:', error);
            });
            }
            console.log("LOG UPLOAD : ", event.target)
            if ( event.target &&  event.target.files.length > 0 ) {
            const formData = new FormData()
            formData.append('files', event.target.files[0])
            axios.post(`${process.env.VUE_APP_API_URL}Files/upload`,formData).then((response) => {
                // console.log("LOG UPDATE : ", response);
                let resultData = response.data
                if (response.data.code == 0){
                this.model.fileImage={
                    fileId: resultData.data.fileId,
                    fileName: resultData.data.fileName,
                    ext: resultData.data.ext,
                };
                console.log("LOG UPDATE : ", this.model);
                }
            }).catch((error) => {
                // Handle error here
                console.error('Error deleting file:', error);
            });

            }
        },

        getColorWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg' )
                return 'text-danger';

            },
        getIconWithExtFile(ext) {
            if (ext == '.png' || ext == '.jpg'|| ext == '.jpeg')
                return 'mdi mdi-file-image-outline';
        },
        addNodeToModel(node ){
            if(node != null && node.id){
            }
        },
        normalizer(node){
            if(node.children == null || node.children == 'null'){
                delete node.children;
            }
        },
    }
};
</script>

<style>
.image-dichvu{
    width: 200px;
}

.tabs{
    width: 100%;
}
.card-header-doctor{

    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
}

.info-doctoc-right{
    box-shadow: 0 10px 40px 10px rgba(0, 0, 0, .0784313725);
    border-radius: 10px;
}

@media (max-width: 768px) {
    .info-doctoc-right{
        margin-top: 20px;
    }
}
</style>