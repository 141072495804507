<template>
  <section class="more-coupon-sec">
    <div class="container">
      <div class="pharmacy-section-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="pharmacy-title">
              <h4>Save more with coupons</h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pharmacy-title-link">
              <router-link to="product-all"
                >See All <i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Flat $200 Off</h4>
              <p>On above $2000</p>
            </div>
            <div class="coupon-code">
              <span>Code200</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Flat $250 Off</h4>
              <p>On above $2500</p>
            </div>
            <div class="coupon-code">
              <span>Code250</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Flat $300 Off</h4>
              <p>On above $3000</p>
            </div>
            <div class="coupon-code">
              <span>Code300</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="coupon-card">
            <div class="coupon-info">
              <h4>Flat $350 Off</h4>
              <p>On above $3500</p>
            </div>
            <div class="coupon-code">
              <span>Code350</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
