import {apiClient} from "@/state/modules/apiClient";

const controller = "News";
export const actions = {
    async getAll({commit}) {
        return apiClient.get(controller + "/get-all-core");
    },
    async getPagingParams({commit}, params) {
        return apiClient.post(controller + "/Get-Paging-Params-Core", params);
    },
    async getPagingParamsTinLienQuan({commit}, params) {
        return apiClient.post(controller + "/get-paging-related-news", params);
    },

    async getPagingParamsDentalKnowledge({commit}, params) {
        return apiClient.post(controller + "/get-list-dental-knowledge", params);
    },

    async create({commit}, values) {
        return apiClient.post(controller + "/create", values);
    },
    async update({commit, dispatch}, values) {
        return apiClient.post(controller + "/update", values);
    },
    async updateAction({commit, dispatch}, values) {
        return apiClient.post(controller + "/update-action", values);
    },
    async delete({commit}, id) {
        return await apiClient.post(controller + "/delete" , id);
    },
    async getById({commit}, id) {
        return apiClient.post(controller + "/get-by-id-core" , id);
    },

    async getContentById({commit}, id) {
        return apiClient.post(controller + "/get-content-by-id" , id);
    },
    async getByMenuId({commit, dispatch}, values) {
        return apiClient.post(controller + "/GetByMenuId", values);
    },

    async getListRelatedNews({commit}, params) {
        return apiClient.post(controller + "/get-list-related-news", params);
    },
    async getPagingRelatedNews({commit}, params) {
        return apiClient.post(controller + "/get-paging-related-news", params);
    },

    async getPagingFeaturedNews({commit}, params) {
        return apiClient.post(controller + "/get-paging-featured-news", params);
    },
};
