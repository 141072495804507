<template>
    <div class="home-pg-twelve">
        <div class="main-wrapper">
            <layoutheader />

            <indextwelvebanner></indextwelvebanner>

            <lab-service></lab-service>

            <twelve-packages></twelve-packages>

            <twelve-best></twelve-best>

            <twelve-book></twelve-book>

            <twelve-faq></twelve-faq>

            <twelve-pricing></twelve-pricing>

            <twelve-about></twelve-about>

            <twelve-footer></twelve-footer>

            <cursor />
            <scrolltotop />
        </div>
    </div>
</template>