import {apiClient} from "@/state/modules/apiClient";

const controller = "NewsSectionMobi";

export const actions = {
    async getAll({commit}) {
        return apiClient.get(controller + "/get-all");
    },
    async getAllSelected({commit}) {
        return apiClient.get(controller + "/get-all-selected"  );
    },
    async getPagingParams({commit}, params) {
        return apiClient.post(controller +"/get-paging-params-core", params);
    },
    async create({commit}, values) {
        return apiClient.post(controller +"/create", values);
    },
    async update({commit, dispatch}, values) {
        return apiClient.post(controller +"/update", values);
    },
    async delete({commit}, values) {
        return await apiClient.post(controller +"/delete", values);
    },
    async getById({commit}, id) {
        return apiClient.post(controller +"/get-by-id-core", id);
    },
};
