const getJson = (item) => {
    return {
        id: item.id,
        userName: item.userName,
        fullName: item.fullName,
        phone: item.phone,
        email: item.email,
        note: item.note,
        avatar: item.avatar,
        unitRole: item.unitRole,
        permissions: item.permissions,
        menu: item.menu
    }
}

const sendJson = (item) => {
    return {
        id: item.id,
        userName: item.userName,
        firstName: item.firstName,
        lastName: item.lastName,
        fullName: item.fullName,
        phone: item.phone,
        email: item.email,
        note: item.note,
        avatar: item.avatar,
        unitRole: item.unitRole,
        permissions: item.permissions,
        menu: item.menu
    }
}

const baseJson = () => {
    return {
        _id: null,
        userName: null,
        fullName: null,
        phone: null,
        email: null,
        note: null,
        avatar: null,
        unitRole: null,
        permissions: null,
        menu: null
    }
}

export const userModel = {
    sendJson, getJson, baseJson
}
