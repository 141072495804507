const getJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      address : item.address,
      phone : item.phone,
      sort : item.sort,
    };
  };
  const sendJson = (item) => {
    return {
      id: item.id,
      name: item.name,
      address : item.address,
      phone : item.phone,
      sort : item.sort,
    };
  };

  const baseJson = () => {
    return {
      id: null,
      name: null,
      address : null,
      phone : null,
      sort : 0,
    };
  };

  export const unitGroupModel = {
    sendJson , getJson, baseJson,
  };
