import {apiClient} from "@/state/modules/apiClient";
const controller = "Geographical";
export const actions = {
    async getList({commit}, code) {
        return apiClient.get(controller + "/get-list-by-level/" + code);
    },
    async getListChild({commit}, code) {
        return apiClient.get(controller + "/get-list-Child/" + code);
    },

};
