<template>
  <div class="main-wrapper home-ten">
    <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="container" style="padding-top: 30px ;">
        <div class="row pb-3" v-if="isArray">
          <div class="col-md-12" v-for="(item, index) in model" :key="index">
            <router-link :to="{
                            path: `chi-tiet/${item.id}`
                            }">
              <div class="row card-chitiet" v-if="index == 0">
                <div class="col-md-6">
                  <img
                      v-if="item.fileImage"
                      class="img-fluid card-chitiet-img"
                      :src="item.urlImg"
                      alt="Post Image"
                  />
                </div>
                <div class="col-md-6">
                  <div class="card-chitiet-title">
                    <h3>{{ item.name }}</h3>
                  </div>
                  <div class="card-chitiet-desc">
                    <p>{{ item.describe }}</p>
                  </div>
                  <b-button
                      class="btn-card-chitiet"
                      style="font-size: 14px;"
                  >
                    Xem thêm
                  </b-button>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row pb-3" v-if="isArray">
          <div class="col-lg-8 col-md-12">
            <div v-for="(item, index) in model" :key="index">
              <router-link :to="{
                              path: `chi-tiet/${item.id}`
                            }">
                <div class="row card-array" v-if="index != 0">
                  <div class="col-md-4 col-sm-4" style="padding: 0;">
                    <img
                        v-if="item.fileImage"
                        class="img-fluid card-array-img"
                        :src="item.urlImg"
                        alt="Post Image"
                    />
                  </div>
                  <div class="col-md-8 col-sm-8" style="padding: 15px;">
                    <div class="card-array-title">
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="card-array-desc">
                      <p>{{ item.describe }}</p>
                    </div>
                    <b-button
                        class="btn-card-array"
                        style="font-size: 14px;"
                    >
                      Xem thêm
                    </b-button>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <blog-sidebar></blog-sidebar>
        </div>

        <div class="row"  v-else>
          <div class="col-lg-8 col-md-12">
            <div class="blog-view">
              <div class="terms-content"  v-if="model && model.content">
                <div class="noidung" v-html="model.content">
                </div>
              </div>
            </div>
          </div>

          <blog-sidebar></blog-sidebar>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
  <footerHome></footerHome>
</template>
<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import BlogSidebar from "@/views/News/baiviet/newSidebar.vue";
export default {
  components: {BlogSidebar},
  data() {
    return {
      title: 'Giới thiệu',
      text: 'Trang chủ',
      text1: 'Giới thiệu',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      BlogGrid: BlogGrid,
      url: `${process.env.VUE_APP_API_URL}files/view/`,
      isArray : true,
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
      this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        this.getData();
      }
    }
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = {
        start: this.currentPage,
        limit: this.perPage,
        sortBy: "sort",
        sortDesc: true,
        menuId : this.$route.params.id

      };
      await this.$store.dispatch("newsStore/getByMenuId", params).then((res) => {
        // console.log("LOG GIOI THIEU  ABC  : ", res)
        if (res.data != null && res.data.data != null && Array.isArray(res.data.data))
        {
          //    console.log("LA ARRAY : ", res.data)
          this.isArray = true;
          this.model = res.data.data;
          this.title = (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          this.text1= (res.data != null && res.data.data.length > 0) ? res.data.data[0].menu.name : "";
          this.totalRows = res.data.totalRows;
          this.numberOfElement = res.data.data.length;
        }else if (res.data != null){
          //    console.log("KHÔNG LA ARRAY 123: ", res)
          this.isArray = false;
          this.model = res.data;
          this.title = this.model != null ? this.model.name : "";
          this.text1= (this.model != null && this.model.menu) ? this.model.menu.name : "";
          if(this.model.content && this.model.content != null){
            this.model.content = this.model.content.replaceAll("https://localhost:5001/api/v1/filesminio/view" , this.url)
          }
        }

      })
    },
  },
}
</script>

<style>

.card-news{
  border-radius: 20px !important;
}

.card-news .blog-image img{
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.card-news .blog-image{
  height: 250px !important;
}

.card-news .blog-image a{
  height: 100%;
}

.noidung {
  text-align: justify;
}

.noidung .image {
  text-align: center;
  width: 100% !important;
}

.content-title {
  text-align: center;
}

.blog-title-details{
  font-size: 16px !important;
  justify-content: center !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
