<template>

  <div class="testimonial-section-ten need-to-know-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 mb-2 text-center aos" data-aos="fade-up">
          <div class="section-header-one text-center">
            <h2 class="section-title"><span>Cảm nhận của </span> khách hàng</h2>
          </div>
        </div>
        <div class="aos" data-aos="fade-up">
          <Carousel ref="carousel" v-bind="settings" :wrapAround="true" :transition="500" :autoplay="5000">
            <Slide v-for="(item, index) in this.list" :key="index.id">
              <div style="height: 100%;" class="img-doctor-sub">
                <img
                    :src="url + item.fileImage?.fileId" alt="logo"
                    class=""
                    style="height: 100%; width: 100%; border-radius: 10px;"
                    loading="lazy"
                />
              </div>
            </Slide>
          </Carousel>
          <div class="btn-w-slide">
            <button class="btn-slide btn-prev" @click="prevSlide"><i class="fa fa-caret-left" aria-hidden="true"></i></button>
            <button class="btn-slide btn-next"@click="nextSlide"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import indexFiveDoctor from "@/assets/json/indexfivedoctor.json";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      indexFiveDoctor: indexFiveDoctor,
      perPage: 4,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1200: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    };
  },

  props: {
    customerReviews: { type : Object}
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.customerReviews;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.next();
    },
    prevSlide() {
      this.$refs.carousel.prev();
    },
  },
};
</script>
