import {apiClient} from "@/state/modules/apiClient";
const controller = "PageInfo";

export const state = {
    reloadAuthUser: false,
}

export const mutations = {
    SET_RELOADAUTHUSER(state, newValue) {
        state.reloadAuthUser = newValue
    }
}
export const actions = {
    async getAll({commit}) {
        return apiClient.get(controller + "/get-evaluate");
    },

    async getPagingParams({commit}, params) {
        return apiClient.post(controller +"/get-paging-params-core", params);
    },
    async createHeader({commit}, values) {
        return apiClient.post(controller +"/create-header", values);
    },
    async deleteHeader({commit}, id) {
        return await apiClient.post(controller +"/delete-header" , id);
    },

    async getAllHeader({commit}, id) {
        return await apiClient.get(controller +"/get-header" , id);
    },



    async createCustomerReview({commit}, values) {
        return apiClient.post(controller +"/create-customer-review", values);
    },
    async deleteCustomerReview({commit}, id) {
        return await apiClient.post(controller +"/delete-customer-review" , id);
    },

    async getAllCustomerReview({commit}, id) {
        return await apiClient.get(controller +"/get-customer-review" , id);
    },




    async createPromotions({commit}, values) {
        return apiClient.post(controller +"/create-promotions", values);
    },
    async deletePromotions({commit}, id) {
        return await apiClient.post(controller +"/delete-promotions" , id);
    },

    async getAllPromotions({commit}, id) {
        return await apiClient.get(controller +"/get-promotions" , id);
    },


    async createClinicSpace({commit}, values) {
        return apiClient.post(controller +"/create-clinic-space", values);
    },
    async deleteClinicSpace({commit}, id) {
        return await apiClient.post(controller +"/delete-clinic-space" , id);
    },

    async getAllClinicSpace({commit}, id) {
        return await apiClient.get(controller +"/get-all-clinic-space" , id);
    },




    async createCustomer({commit}, values) {
        return apiClient.post(controller +"/create-customer", values);
    },
    async updateCustomer({commit, dispatch}, values) {
        return apiClient.post(controller +"/update-customer", values);
    },
    async deleteCustomer({commit}, id) {
        return await apiClient.post(controller +"/delete-customer" , id);
    },


    async updateTitle({commit, dispatch}, values) {
        return apiClient.post(controller +"/update-evaluate", values);
    },

    async getById({commit}, id) {
        return apiClient.post(controller +"/get-by-id-core", id);
    },
    async getInfo({commit}) {
        return apiClient.get(controller +"/get-info");
    },


    async getByIdCongDan({commit}, id) {
        return apiClient.get(controller +"/get-by-id-cong-dan/" + id);
    },
};
