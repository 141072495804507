<template>
  <!-- Home Banner -->
  <section class="banner-section">
    <div class="banner-slider" style="margin-top: 125px; max-height: 90vh;">

      <Carousel ref="carousel" v-bind="settings" :wrapAround="true" :transition="500" :autoplay="7000" v-if="this.list">
        <Slide v-for="(item, index) in this.list" :key="index.id">
            <img
              v-if="item"
              :src="url + item.fileImage?.fileId" alt="Image"
            />
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
  <!-- /Home Banner -->

  <!-- Specialities Section -->
  <section class="specialities-section-one">
    <div class="floating-bg">
      <img src="@/assets/img/phuongthanh/icon-teeth-72.png" alt="Image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider mt-3 mb-3">
            <h2 class="section-title">5 lý do nên chọn <span>Nha khoa Phương Thành?</span></h2>
          </div>
        </div>
      </div>
      <div class="specialities-block aos" data-aos="fade-up">
        <ul>
          <li v-for="(speciality, index) in indexfivespecialities" :key="index">
            <div class="specialities-item">
              <div class="specialities-img">
                <div class="hexogen">
                  <img
                    :src="require(`@/assets/img/icons/${speciality.imageSrc}`)"
                    :alt="speciality.altText"
                    style="width: 50px;"
                  />
                </div>
              </div>
              <p>{{ speciality.title }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider mt-3 mb-3">
            <p class="section-title"
              style="
                color: #000;
                font-weight: bold;
              "
            >
              <span style="color: red;">*</span> Hợp tác với các chuyên gia đầu ngành trong nước và quốc tế
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexfivespecialities from "@/assets/json/indexfivespecialities.json";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
import { computed } from "vue";
import * as Yup from "yup";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      startdate: currentDate,
      indexfivespecialities: indexfivespecialities,
      list: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    header: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.list = val.header;
        console.log("LOG WATCH LIST ", this.list )
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/search-2");
    },
  },
};
</script>
<style>
.carousel__pagination{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50px);
}

.carousel__pagination-button--active::after{
  background-color: #d4a04c !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 5px !important;
}

.carousel__pagination-button::after{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d4a04c5d;
}
</style>
