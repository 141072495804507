<template>
  <section class="pricing-section pricing-section-ten">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider">
            <h2 class="section-title" style="margin-bottom: 0px;"><span>Hình ảnh</span> không gian nha khoa</h2>
          </div>
        </div>
      </div>
      <div class="aos" data-aos="fade-up">
        <Carousel ref="" v-bind="settings" :breakpoints="breakpoints" :wrap-around="true" :transition="500" :autoplay="5000">
          <Slide v-for="item in listImage" :key="item">
            <div class="list-card-nk">
              <div class="item-doctor">
                <div class="img-library">
                  <div style="height: 100%;" class="img-doctor-sub">
                    <img
                      :src="url + item.fileImage.fileId"
                      class=""
                      style="height: 100%; width: 100%;"
                      loading="lazy"
                    />
                    <!-- <img
                      :src="require(`@/assets/img/phuongthanh/${item.Image}`)"
                      class=""
                      style="height: 100%; width: 100%;"
                      loading="lazy"
                    /> -->
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>

    </div>
  </section>

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import {Carousel, Pagination, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    clinicSpace: { type : Object}
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        576: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      listImage: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  watch:{
    '$props':{
      handler: function (val) {
        this.listImage = val.clinicSpace;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  computed: {
  },
  methods: {


    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>

<style>
.list-card-nk{
  padding: 10px;
}
.flickity-viewport{
  height: 100% !important;
}

.img-library{
  height: 300px;
}

.img-library img{
  height: 300px;
  border-radius: 10px;
}
.card-img{
    height: 300px;
    padding: 10px 10px 0 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.card-img div img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.box-text{
    padding: 0px 10px
}

.box-info{
  height: 130px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-name-sen{
  position: absolute;
  background-color: #d4a04c;
  padding: 5px;
  color: #fff;
  margin: 0;
  top: 269px;
  left: 20px;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  font-size: clamp(0.8125rem, 0.7679rem + 0.1429vw, 0.875rem);
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.decs-cate-sen{
    font-size: 14px;
    text-align: justify;
    color: #000;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.btn-detail{
    border: 2px solid #d4a04c !important;
    background-color:#fff !important;
    color: #d4a04c !important;
    border: none;
    border-radius: 5px;
    font-size: clamp(0.8125rem, 0.6786rem + 0.4286vw, 1rem) !important;
}

.btn-detail:hover{
    background-color: #d4a04c !important;
    color: #fff !important;
}

.card-price{
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-price span{
  background-color: #CCFBF1;
  color: #14B8A6;
  font-weight: bold;
  padding: 5px;
  font-size: clamp(0.625rem, 0.4911rem + 0.4286vw, 0.8125rem);
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>

