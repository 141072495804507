<template>
  <!-- Footer -->
  <footer class="footer footer-one footer-ten">
    <div class="footer-ten-bg">
      <img src="@/assets/img/bg/hexagen-group-4.png" alt="Image" style="height: 100%;"/>
      <img src="@/assets/img/bg/hexagen-group-5.png" alt="Image" style="height: 100%;"/>
    </div>
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="footer-details">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4" style="display: flex; justify-content: center; align-items: center;">
              <div class="footer-widget footer-about">
                <div class="footer-logo" v-if="this.listFooter">
                  <img :src="url + this.listFooter.logo?.fileId" alt="logo" />
                </div>
                <div class="footer-about-content" v-if ="this.listFooter">
                  <p style="text-align: center; padding-bottom: 5px;">
                    {{ this.listFooter.companyName }}
                  </p>
                </div>
                <div class="footer-about-content" style="text-align: center;">
                  <span style="text-align: center; padding-bottom: 5px; font-size: 15px;">
                    Công ty TNHH MTV Nha khoa Phương Thành nơi hội tụ TÂM - TÀI - ĐỨC
                  </span>
                </div>
<!--                <div class="footer-contact-info mt-3">-->
<!--                  <h4>Nhận thông tin mới nhất</h4>-->
<!--                  <div style="position: relative;">-->
<!--                    <input-->
<!--                        value-->
<!--                        class="email-to-info"-->
<!--                        placeholder="Nhập email để nhận thông tin từ Phương Thành"-->
<!--                    >-->

<!--                    <input-->
<!--                        type="submit"-->
<!--                        name="submit"-->
<!--                        class="btn-submit-email"-->
<!--                        value="Đăng ký"-->
<!--                    >-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8">
              <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-7">
                  <div class="footer-widget footer-contact">
                    <div class="footer-contact-info">
                      <h4>THÔNG TIN NHA KHOA</h4>
                      <div class="footer-address">
                        <div style="color: #6B7280;" v-if="this.listFooter">
                          <i class="feather-map-pin"></i>
                          {{this.listFooter.address }}
                        </div>
                      </div>
                      <div class="footer-address">
                        <div style="color: #6B7280;"  v-if="this.listFooter">
                          <i class="feather-phone-call" style="color: #6B7280;"></i>
                          {{this.listFooter.phone }}
                        </div>
                      </div>
                      <!-- <div class="footer-address">
                        <p><i class="feather-user"></i>Người chịu trách nhiệm nội dung: Ông Phạm Kim Thành – Giám đốc.</p>
                      </div> -->
                      <div class="footer-address">
                        <div>
                          <div style="font-size: 15px; color: #6B7280; font-weight: 500; margin-bottom: 7px;">
                            <i class="fa-regular fa-clock"></i>
                              Giờ mở cửa:
                          </div>
                          <div style="color: #6B7280; margin-bottom: 7px;">
                            Thứ 2 - Thứ 3:  Sáng: 7:00 - 13:00 và Chiều: 14h30 - 20:00
                          </div>
                          <div style="color: #6B7280; margin-bottom: 7px;">
                            Thứ 5 - Chủ nhật:  Sáng: 7:00 - 13:00 và Chiều: 14h30 - 20:00
                          </div>
                          <div style="color: #6B7280; margin-bottom: 7px;">
                            Thứ 4: Nghỉ
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 footer-intro">
                  <div class="footer-widget footer-contact footer-intro-left mb-4" v-for="(item, index) in listFooterMenu">
                    <h4>{{item.name}}</h4>
                    <div class="footer-contact-info">
                      <div class="footer-address"  v-for="(children, index) in item.children">
                        <router-link :to="`ban-tin/${children.menuId}`">
                          <p>{{children.name}}</p>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="copyright-text">
            <p class="mb-0">
              © {{ new Date().getFullYear() }} Bản quyền thuộc về Nha Khoa Phương Thành.
            </p>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  data() {
    return {
      listFooter: [],
      listFooterMenu: [],
      url : `${process.env.VUE_APP_API_URL}files/view/`,
      urlFile:`${process.env.VUE_APP_API_URL}files/view`,
    };
  },
  props: {
    footer: { type : Object}
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  created() {
    this.getData();
    this.getListFooterMenu();
  },
  // watch:{
  //   '$props':{
  //     handler: function (val) {
  //       this.listFooter = val.footer;
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    async getData() {
        await this.$store.dispatch("headerStore/getAllFooter" ).then(res => {
            this.listFooter = res.data;
        });
    },
    async getListFooterMenu() {
      await this.$store.dispatch("menuFooterStore/getTreeList" ).then(res => {
        this.listFooterMenu = res.data;
      });
    },
  },
};

</script>

<style>

</style>
