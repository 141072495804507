<template>
    <div class="main-wrapper home-ten">
        <layoutheader :class="{ 'header-space': showHeaderSpace }" ref="header" />
        <breadcrumb :title="title" :text="text" :text1="text1" />
        <div class="page-wrapper">
            <!-- Giới thiệu chung -->
            <div class="container" style="padding-top: 30px ;">
                <div class="row intro-nhakhoa">
                    <div class="col-lg-6 col-md-6 item-intro">
                        <p class="title-intro color font-bold">
                            NHA KHOA PHƯƠNG THÀNH
                        </p>
                        <p class="title-intro-sub color font-light">
                            Tiên phong dẫn đầu
                        </p>
                        <p class="decs-intro font-regular">
                            Thành lập từ năm 2014, trên suốt chặng đường đi qua chúng tôi luôn làm việc với phương châm lấy tâm đức,
                            sự chính trực và tri thức mang đến nụ cười hạnh phúc hơn mong đợi cho khách hàng.
                        </p>
                        <p class="decs-intro font-regular">
                            Sự phát triển đa phương diện về tổ chức, cơ sở vật chất, trang thiết bị,
                            nguồn nhân lực,kỹ thuật chuyên môn và hoạt động xã hội tích cực nha khoa ngày càng góp phần
                            vào sự phát triển chung của xã hội.
                        </p>
                        <p class="decs-intro font-regular text-center mb-1">
                            Đoàn kết - Kỷ luật - Nhân văn
                        </p>
                        <p class="decs-intro font-regular text-center">
                            An toàn - Chất lượng - Hiệu quả
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 img-intro">
                        <img src="@/assets/img/phuongthanh/home.jpg" alt="">
                    </div>
                </div>

            </div>
            <!-- /Giới thiệu chung -->

            <!-- TẦM NHÌN - SỨ MỆNH -->
            <div class="container" style="padding-top: 30px ;">
                <div class="row vision-nhakhoa">
                    <div class="col-lg-6 col-md-6 img-vision">
                        <div class="row">
                            <div class="col-lg-6">

                            </div>
                            <div class="col-lg-6 bg-vision">
                            </div>
                        </div>
                        <img src="@/assets/img/phuongthanh/tamnhin.png" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 item-vision">
                        <div class="row">
                            <div class="col-lg-12 content-vision">
                                <div>
                                    <h2><i class="fa-solid fa-eye me-2"></i> Tầm nhìn</h2>
                                    <p class="font-light">
                                        Đến năm 2030, Công ty TNHH MTV Nha khoa Phương Thành phải là trung tâm điều trị, đào tạo, nghiên cứu, sản xuất lĩnh vực Răng Hàm Mặt hàng đầu Việt Nam.
                                    </p>
                                    <img src="@/assets/img/phuongthanh/vector.png" alt="">
                                    <h2><i class="fa-solid fa-bullseye me-2"></i> Sứ mệnh</h2>
                                    <p class="font-light">
                                        Mang sự hài lòng hơn mong đợi đến với khách hàng khi điều trị tại Nha khoa Phương Thành. Nâng cao sức khỏe răng miệng cho cộng đồng.
                                    </p>
                                    <div class="img-tamnhin">
                                        <img src="@/assets/img/phuongthanh/tamnhin.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /TẦM NHÌN - SỨ MỆNH -->

            <!-- GIÁ TRỊ CỐT LỖI -->
             <div class="value-nhakhoa" style="margin-top: 30px ;">
                 <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
                     <div class="row">
                         <div class="col-lg-2 col-md-12 title-value">
                            <h1 class="font-bold color">GIÁ TRỊ CỐT LỖI</h1>
                         </div>
                         <div class="col-lg-5 col-md-6">
                            <div class="item-value">
                                <h2 class="font-bold color">Thấu Cảm</h2>
                                <p>Luôn tìm cách để thấu hiểu, đồng cảm và trân trọng với từng khách hàng.</p>
                            </div>
                            <div class="item-value">
                                <h2 class="font-bold color">Chính Trực</h2>
                                <p>Luôn nổ lực để làm những điều đúng cho khách hàng và cộng đồng ở mọi thời điểm.</p>
                            </div>
                            <div class="item-value">
                                <h2 class="font-bold color">Trách Nhiệm</h2>
                                <p>Đội ngũ Công ty TNHH MTV Nha khoa Phương Thành luôn hành xử với tinh thần trách nhiệm,
                                    chuẩn mực và đạo đức nghề nghiệp cao nhất đối với khách hàng và cộng đồng.</p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="item-value">
                                <h2 class="font-bold color">Đoàn Kết</h2>
                                <p>Là nền móng trong hệ thống giá trị cốt lõi của Công ty TNHH MTV Nha khoa Phương Thành,
                                    sự đoàn kết của các thành viên của công ty là sức mạnh gắn tập thể thành một khối thống nhất
                                    tạo nên sức mạnh tổng thể góp phần cho sự thành công và phát triển bền vững của công ty.</p>
                            </div>
                            <div class="item-value">
                                <h2 class="font-bold color">Ưu việt</h2>
                                <p>Công ty TNHH MTV Nha khoa Phương Thành luôn hướng đến những kết quả điều trị tốt nhất, an toàn nhất,
                                    luôn tự hoàn thiện và phục vụ khách hàng tốt nhất.</p>
                            </div>
                        </div>
                     </div>
                 </div>
             </div>
            <!-- /GIÁ TRỊ CỐT LỖI -->

            <!-- TỔ CHỨC ĐOÀN THỂ -->
                <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
                    <div class="row">
                        <div class="col-lg-12 title-group">
                            <h2 class="color font-bold">Các tổ chức - Đoàn thể</h2>
                        </div>
                        <div class="col-lg-4 col-md-4 item-group">
                            <div class="card-group">
                                <img src="@/assets/img/phuongthanh/chibo.png" alt="">
                                <div class="name-card-group">
                                    <span>CHI BỘ ĐẢNG CSVN</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 item-group">
                            <div class="card-group">
                                <img src="@/assets/img/phuongthanh/congdoan.png" alt="">
                                <div class="name-card-group">
                                    <span>CÔNG ĐOÀN CƠ SỞ</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 item-group">
                            <div class="card-group">
                                <img src="@/assets/img/phuongthanh/tncs.png" alt="">
                                <div class="name-card-group">
                                    <span>ĐOÀN TNCS HỒ CHÍ MINH</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- /TỔ CHỨC ĐOÀN THỂ -->

            <!-- GIẢI THƯỞNG TIÊU BIỂU -->
            <div class="prize">
                <div class="container">
                    <div class="row" style="padding: 30px;">
                        <div class="col-lg-4 col-md-12 title-prize">
                            <h1 class="color font-bold">Giải thưởng tiêu biểu</h1>
                            <b-tabs pills nav-class="flex-column" card v-model="activeTab" style="flex-wrap: wrap; margin-right: 0px;">
                                <b-tab>
                                    <template #title>
                                        <i class="fa-solid fa-building" style="margin-right: 7px;"></i> Doanh nghiệp
                                    </template>
                                </b-tab>
                                <b-tab>
                                    <template #title>
                                        <i class="fa-solid fa-user-tie" style="margin-right: 7px;"></i>Cá nhân
                                    </template>
                                </b-tab>
                            <!-- Thêm các tab khác ở đây nếu cần -->
                            </b-tabs>
                        </div>
                        <div class="col-lg-8">
                            <!-- Nội dung cho "Thông tin chung" -->
                            <div v-if="activeTab === 0">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="wrapper-prize">
                                            <ul class="sessions">
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2019'" :class="{ active: currentYear === '2019' }" class="time-link">2019</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2020'" :class="{ active: currentYear === '2020' }" class="time-link">2020</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2021'" :class="{ active: currentYear === '2021' }" class="time-link">2021</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2022'" :class="{ active: currentYear === '2022' }" class="time-link">2022</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2023'" :class="{ active: currentYear === '2023' }" class="time-link">2023</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2024'" :class="{ active: currentYear === '2024' }" class="time-link">2024</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <div v-show="currentYear === '2019'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2019</h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2020'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2020</h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2021'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2021</h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2022'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2022</h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2023'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2023</h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2024'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2024</h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Nội dung cho "Tab khác" -->
                            <div v-if="activeTab === 1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="wrapper-prize">
                                            <ul class="sessions">
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2019'" :class="{ active: currentYear === '2019' }" class="time-link">2019</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2020'" :class="{ active: currentYear === '2020' }" class="time-link">2020</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2021'" :class="{ active: currentYear === '2021' }" class="time-link">2021</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2022'" :class="{ active: currentYear === '2022' }" class="time-link">2022</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2023'" :class="{ active: currentYear === '2023' }" class="time-link">2023</a>
                                                </li>
                                                <li>
                                                    <a href="#" @click.prevent="currentYear = '2024'" :class="{ active: currentYear === '2024' }" class="time-link">2024</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <div v-show="currentYear === '2019'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2019-2
                                            </h3>
                                            <p style="text-align: justify;">
                                                Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2020'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2020-2
                                            </h3>
                                            <p style="text-align: justify;">
                                                Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2021'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2021-2
                                            </h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2022'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2022-2
                                            </h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2023'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2023-2
                                            </h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                        <div v-show="currentYear === '2024'" class="card-giaithuong">
                                            <h3 class="color" style="text-align: justify; margin-bottom: 20px;">
                                                Đạt giải thưởng “Doanh nhân năng động sáng tạo năm 2024-2
                                            </h3>
                                            <p style="text-align: justify;">Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp
                                                tại buổi trao giải Ông Phạm Kim Thành, chụp ảnh kỷ niệm cùng
                                                Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải Ông Phạm Kim Thành,
                                                chụp ảnh kỷ niệm cùng Ông Lê Quốc Phong - Bí thư Tỉnh ủy Đồng Tháp tại buổi trao giải
                                            </p>
                                            <img src="@/assets/img/phuongthanh/giaithuong.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

            <!-- /GIẢI THƯỞNG TIÊU BIỂU -->

            <!-- HỆ THỐNG CƠ SỞ VẬT CHẤT -->
            <div class="container hethong" style="padding-top: 30px;">
                <div class="row" style="display: flex; flex-direction: row;">
                    <div class="col-lg-8 col-md-12 col-sm-12 img-hethong">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 5px;">
                                <img src="@/assets/img/phuongthanh/coso1.jpg" alt="">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso2.jpg" alt="">
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso3.jpg" alt="">
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso4.jpg" alt="">
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 5px;">
                                        <img src="@/assets/img/phuongthanh/coso5.jpg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 title-hethong">
                        <div>
                            <h2 class="color font-bold">Hệ thống</h2>
                            <h2 class="color font-bold">Cơ sở vật chất</h2>
                            <p style="color: #000; text-align: justify;">
                                Trung tâm Nha khoa Phương Thành luôn cập nhật các trang thiết bị máy móc 
                                ngành nha trên Thế giới kịp thời mang đến những kỹ thuật điều trị mới 
                                và hiệu quả cao đến người dân.
                            </p>
                            <button>
                                <div class="arrow">
                                    <i class="fa-solid fa-arrow-right"></i>
                                </div>
                                Xem thêm
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /HỆ THỐNG CƠ SỞ VẬT CHẤT -->

            <!-- ĐỘI NGŨ BÁC SĨ -->
            <div class="team-doctor" style="margin-top: 30px;">
                <div class="container" style="padding-top: 30px;">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 title-team-doctor">
                            <div>
                                <h2 class="color font-bold">Đội ngũ Bác sĩ</h2>

                                <p style="color: #000; text-align: justify;"> Với đội ngũ bác sĩ tốt nghiệp chính quy, hơn 40% sau đại học, chúng tôi cam kết ...
                                </p>
                                <button>
                                    <div class="arrow">
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </div>
                                    Xem thêm
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="row list-doctor">
                              <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                <img src="@/assets/img/doctors/BS_Thanh.jpg" alt="">
                                <div class="name-doctor">
                                  <span>BS Phạm Kim Thành</span>
                                </div>
                              </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/doctors/BS_Lam.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Lưu Tuấn Lâm</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/doctors/BS_Nguyen.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Lê Nguyễn Hạnh Nguyên</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/doctors/BS_Diep.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Phạm Ngọc Diệp</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/doctors/BS_Khoa.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Bùi Minh Khoa</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 mb-3 item-doctor">
                                    <img src="@/assets/img/doctors/BS_Man.jpg" alt="">
                                    <div class="name-doctor">
                                        <span>BS. Hà Minh Mẫn</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /ĐỘI NGŨ BÁC SĨ -->

            <!-- ĐỐI TÁC -->

            <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <h2 class="color font-bold text-center">Đối tác của chúng tôi</h2>
                    </div>
                </div>
                <div class="row list-doitac">
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac1.png" alt="">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac2.png" alt="">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac3.png" alt="">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 mb-3 item-doitac">
                        <img src="@/assets/img/phuongthanh/doitac4.png" alt="">
                    </div>
                </div>
            </div>

            <!-- /ĐỐI TÁC -->

        </div>
    </div>
  <footerHome></footerHome>
</template>

<script>
import BlogGrid from "@/assets/json/blog-grid.json";
import {pageInfoModel} from "@/models/pageInfoModel";
export default {
  components: {},
  data() {
    return {
      title: 'Giới thiệu',
      text: 'Trang chủ',
      text1: 'Giới thiệu',
      numberOfElement: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      model: [],
      activeTab: 0, // Tab mặc định là tab đầu tiên
      BlogGrid: BlogGrid,
      currentYear: '2024', // Năm mặc định được chọn
      pageInfo : pageInfoModel.baseJson(),
    }
  },

  watch: {
    $route(to, from) {
      //  console.log("LOG WATCH DATA : ")
    //   this.getData();
    },
    perPage: {
      deep: true,
      handler(val){
        // this.getData();
      }
    },
    currentPage:{
      deep: true,
      handler(val){
        //   console.log("this.perpage", this.currentPage);
        // this.getData();
      }
    }
  },

  mounted() {
  },
  created() {
    this.getData();
  },
  methods: {
    show2019() {
        this.currentYear = '2019';
    },
    show2020() {
        this.currentYear = '2020';
    },
    show2021() {
        this.currentYear = '2021';
    },
    show2022() {
        this.currentYear = '2022';
    },
    show2023() {
        this.currentYear = '2023';
    },
    show2024() {
        this.currentYear = '2024';
    },
    async getData() {
      await this.$store.dispatch("pageInfoStore/getInfo").then(res => {
        if (res != null && res.code ===0)
        {
          this.pageInfo = pageInfoModel.getJson(res.data);
        }

      });
    },
  },
}
</script>

<style>

.color{
    color: #D6A24D;
}

.font-bold{
    font-family: 'Be Bold';
}

.font-light{
    font-family: 'Be Light';
}

.font-regular{
    font-family: 'Be Regular';
}


/* **************** GIỚI THIỆU CHUNG *************** */

.item-intro{
    padding-right: 20px !important;
}
.intro-nhakhoa .item-intro .title-intro{
    font-size: clamp(0.9375rem, 0.3472rem + 1.8889vw, 2rem); /*32 - 15*/
    margin-bottom: 10px;
}

.intro-nhakhoa .item-intro .title-intro-sub{
    font-size: clamp(0.9375rem, 0.4861rem + 1.4444vw, 1.75rem); /*28 - 15*/
    margin-bottom: 10px;
}

.intro-nhakhoa .item-intro .decs-intro{
    font-size: clamp(0.9375rem, 0.8333rem + 0.3333vw, 1.125rem); /*18 - 15*/
    text-align: justify;
    color: #000;
    margin-bottom: 10px;
}

.img-intro{
    padding-left: 20px !important;
}

.img-intro img{
    border-radius: 30px;
}

/* **************** HẾT GIỚI THIỆU CHUNG *************** */

/* **************** TẦM NHÌN - SỨ MỆNH *************** */
.img-vision {
    position: relative;
}

.img-vision img{
    position: absolute;
    top: 50%;
    padding-right: 20px;
    height: 400px;
    transform: translateY(-50%);
}

.img-vision .bg-vision{
    background-color: #D6A24D;
    height: 500px;
    border-radius: 30px 0 0 30px;
}

.img-tamnhin{
    display: none;
}

.item-vision{
    background-color: #D6A24D;
    height: 500px;
}

.item-vision .content-vision{
    height: 500px;
    display: flex;
    align-items: center;
    padding: 30px 60px 30px 20px;
}

.item-vision .content-vision h2, .item-vision .content-vision p{
    color: #fff;
    text-align: justify;
}

@media (max-width: 768px) {
    .img-vision{
        overflow: hidden;
        display: none;
    }

    .img-vision img{
       display: none;
    }

    .img-vision .bg-vision{
        background-color: inherit;
        height: 400px;
    }
    .img-tamnhin{
        display: block;
        text-align: center;
    }
    .item-vision{
        height: 100% !important;
    }
    .item-vision .content-vision{
        height: 100% !important;
        padding: 30px 20px !important;
    }
}

@media (max-width: 576px) {
    .img-vision .bg-vision{
        height: 300px;
    }
}
/* **************** HẾT TẦM NHÌN - SỨ MỆNH *************** */


/* **************** GIÁ TRỊ CỐT LỖI *************** */
.value-nhakhoa{
    background-color: #D6A24D1A;
}

.title-value{
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-value h1{
    display: inline-block;
    transform-origin: center center;
    white-space: nowrap; /* Đảm bảo chữ không xuống dòng */
    transform: rotate(-90deg);
}

.item-value{
    padding: 20px 0;
    border-bottom: 2px solid #D6A24D;
}
.item-value p {
    color: #000;
    text-align: justify;
    margin: 0;
}

@media (max-width: 992px) {
    .title-value h1{
        transform: rotate(0deg);
    }
}

/* **************** HẾT GIÁ TRỊ CỐT LỖI *************** */

/* **************** CÁC TỔ CHỨC ĐOÀN THỂ *************** */
.title-group{
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-group{
    margin-bottom: 20px;
}

.card-group{
    border-radius: 10px 10px 0 0;
}

.card-group img{
    border-radius: 10px 10px 0 0;
    width: 100%;
    min-height: 230px;
    max-height: 230px;
    object-fit: cover;
}

.name-card-group{
    background-color: #D6A24D;
    padding: 7px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-card-group{
    color: #fff;
    font-weight: bold;
}

@media (max-width: 768px) {
    .card-group img{
        max-height: 330px;
    }
}

/* **************** HẾT CÁC TỔ CHỨC ĐOÀN THỂ *************** */

/* **************** GIẢI THƯỞNG TIÊU BIỂU *************** */
.prize{
    background-color: #D6A24D1A;
}
.wrapper-prize ul,
.wrapper-prize li {
    list-style: none;
    padding: 0;
}

.wrapper-prize {
    /* background: #eaf6ff; */
    padding: 0 2rem;
    border-radius: 15px;
}
.wrapper-prize .sessions {
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
}
.wrapper-prize li {
    text-align: right;
    padding-bottom: 1.5rem;
    border-right: 1px solid #ccc;
    position: relative;
    padding-right: 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.wrapper-prize li a {
    display: block;
    padding-bottom: 10px;
    color: black; /* Màu chữ mặc định */
    text-decoration: none;
}

.wrapper-prize li a.active {
    font-weight: bold;
    color: #D6A24D; /* Màu chữ khi được chọn */
}

/* Nút tròn màu sắc cho từng năm */
.sessions li a:before {
    content: "";
    width: 15px;
    height: 15px;
    background: #ccc; /* Màu mặc định */
    border: 1px solid #ccc; /* Màu viền mặc định */
    border-radius: 50%;
    position: absolute;
    right: -7px;
    top: 5px;
    transition: background-color 0.3s, border-color 0.3s;
}

/* Màu sắc cho năm được chọn */
.sessions li a.active:before {
    background-color: #D6A24D; /* Màu nền cho nút tròn khi được chọn */
    border-color: #D6A24D; /* Màu viền cho nút tròn khi được chọn */
}


.wrapper-prize .time {
    color: #2a2839;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

}
.wrapper-prize p {
    color: #4f4f4f;
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 0.4rem;
}
.year-content {
    display: none;
}

.year-content:target {
    display: block;
}

/* .time-link {
    display: block;
    padding: 10px 0;
    cursor: pointer;
} */

.time-link {
    display: block;
    padding-bottom: 10px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.time-link.active {
    font-weight: bold;
    color: #D6A24D; /* Đổi màu khi được chọn */
}

.year-content {
    margin-top: 20px;
}

.card-giaithuong img{
    width: 100%;
    height: 350px;
    border-radius: 30px;
    object-fit: cover;
}

@media (max-width: 992px) {
    .title-prize{
        text-align: center;
        margin-bottom: 20px;
    }
    .wrapper-prize{
        padding: 0;
    }
}

.title-prize .nav-pills .nav-link.active{
    background-color: #D6A24D;
    text-align: left;
}

.title-prize .nav-pills .nav-link{
    width: 100%;
    color: #000;
    text-align: left;
}

.title-prize .tab-content{
    display: none;
}

@media (max-width: 1000px) {
    .title-prize .flex-column{
        flex-direction: row !important;
    }
}

/* **************** HẾT GIẢI THƯỞNG TIÊU BIỂU *************** */


/* **************** HỆ THỐNG CƠ SỞ VẬT CHẤT *************** */

.title-hethong{
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-hethong div{
    padding: 20px;
}
.hethong img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title-hethong button{
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-hethong button .arrow{
    margin-right: 10px;
    padding: 5px 10px;
    border: 2px solid #ccc;
    border-radius: 50%;
}

@media (max-width: 992px) {
    .img-hethong{
        order: 2;
    }
    .title-hethong{
        order: 1;
    }
}

/* **************** HẾT HỆ THỐNG CƠ SỞ VẬT CHẤT *************** */


/* **************** ĐỘI NGŨ BÁC SĨ *************** */

.team-doctor{
    background-color: #D6A24D1A;
}
.title-team-doctor{
    display: flex;
    align-items: center;
}
.title-team-doctor div{
    padding: 20px;
}

.title-team-doctor button{
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-team-doctor button .arrow{
    margin-right: 10px;
    padding: 5px 10px;
    border: 2px solid #ccc;
    border-radius: 50%;
}

.list-doctor .item-doctor img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-color: #ccc;
}

.list-doctor .item-doctor .name-doctor{
    padding: 8px;
    background-color: #D6A24D;
    color: #fff;
    text-align: center;
}

.list-doctor .item-doctor .name-doctor span{
    font-size: 14px;
    font-weight: bold;
}

/* **************** HẾT ĐỘI NGŨ BÁC SĨ *************** */


/* **************** ĐỐI TÁC *************** */
.list-doitac{
    padding: 30px;
}

.list-doitac .item-doitac{
    height: 200px;
    text-align: center;
}

.list-doitac .item-doitac img{
    height: 100%;
}


/* **************** HẾT ĐỐI TÁC *************** */
</style>
