<template>
    <div class="main-wrapper">
      <layoutheader></layoutheader>
      <breadcrumb :title="title" :text="text" :text1="text1" />
  
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-5 col-lg-3 col-xl-3">
              <div class="stickysidebar">
                <div class="card search-filter">
                  <div class="card-header">
                    <h4 class="card-title mb-0">Tìm kiếm</h4>
                  </div>
                  <div class="card-body">
                    <div class="filter-widget">
                      <h4>DỊCH VỤ</h4>
                      <div v-for="item in list">
                        <label class="custom_check">
                          <input type="checkbox" name="gender_type"/>
                          <span class="checkmark"></span> {{item.name}}
                        </label>
                      </div>
                    </div>
                    <div class="btn-search">
                      <b-button type="button" class="btn w-100">Tìm kiếm</b-button>
                    </div>
                  </div>
                </div>
                <!-- /Search Filter -->
              </div>
            </div>
            <danh-Sach-Bac-Si></danh-Sach-Bac-Si>
          </div>
        </div>
      </div>
    </div>
    <indexfivefooter></indexfivefooter>
  </template>
  
  <script>
  import patientfavourite from "@/assets/json/patientfavourites.json";
  import DanhSachBacSi from "@/views/News/dat_Lich_Bac_Si/danhSachBacSi.vue";
  export default {
    components: {DanhSachBacSi},
    data() {
      return {
        title: "Danh sách bác sĩ",
        text: "Home",
        text1: "Danh sách bác sĩ",
        patientfavourite: patientfavourite,
        list: [],
      };
    },
    created() {
      this.getData();
    },
    methods: {
      async getData(){
        await  this.$store.dispatch("serviceStore/getAllSelected").then((res) =>{
          this.list = res.data || [];
        })
      },

    }
  };
  </script>
  